import langs from "@cospired/i18n-iso-languages"
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import CssBaseline from '@material-ui/core/CssBaseline'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ContactsIcon from '@material-ui/icons/Contacts'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MailIcon from '@material-ui/icons/Mail'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import SettingsIcon from '@material-ui/icons/Settings'
import { globalHistory } from "@reach/router"
import 'draft-js-alignment-plugin/lib/plugin.css'
import 'draft-js-focus-plugin/lib/plugin.css'
import { changeLocale, IntlContextConsumer, navigate, useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { isLoggedIn } from "../services/auth"
import Notification from '../tools/myNotification'
import "./layout.css"
import NavBar from "./navBar"
import languagesLocal from "../intl/languages"

langs.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"))
langs.registerLocale(require("@cospired/i18n-iso-languages/langs/pt.json"))

const initialState = {
  isDarkMode: false,
};

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "rgba(255, 255, 255)",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'relative'
  },
  contentglobal: {
    flexGrow: 1,
    //padding: theme.spacing(3)
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    // background: "rgb(0,82,204)",
    // background:
    //   "linear-gradient(0deg, rgba(0,82,204,1) 20%, rgba(38,132,255,1) 80%)",
  },
  toolbar: theme.mixins.toolbar,
  footer: {
    textAlign: "center",
    padding: "48px 24px",
    backgroundColor: "#1C1E21",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listroot: {
    padding: 5,
  },
  listitemroot: {
    borderRadius: 5,
    marginBottom: 3,
    height: 40,
    "&:hover, &focusVisible, &selected": {
      backgroundColor: "rgba(9, 30, 66, 0.04)",
    },
    "&:active, &focusVisible": {
      backgroundColor: "rgb(222, 235, 255)",
    },
  },
  selected: {
    backgroundColor: "rgba(0, 0, 0, 0) !important",
    color: "#286efa",
  },
  flexContainer: {
    display: "inline-flex;",
    flexDirection: "row",
    padding: 0,
  },
  flexContainer2: {
    display: "flex;",
    flexDirection: "row",
    padding: 0,
  },
  footerlang: {
    color: "#ccc",
    cursor: "pointer",
    "&:hover": {
      color: "white",
    },
  },
  footerlangsel: {
    color: "white",
    cursor: "pointer",
    borderBottom: "2px solid white",
    "&:hover": {
      color: "white",
    },
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles();
  const [open1, setOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [opendialog, setOpenDialog] = useState(false);
  const [prevUrl, setPrevUrl] = useState("");
  const intl = useIntl();
  const mypath = globalHistory.location.pathname;
  const [myLang, setMyLang] = useState();

  function handleClickLanding() {
    setOpen1(!open1);
    handleClickOpenDialog();
  }
  function handleClickSettings() {
    setOpen(!open);
  }

  function handleClickOpenDialog() {
    setOpenDialog(true);
  }
  function handleCloseDialog() {
    setOpenDialog(false);
  }

  var regEx = "^\/" + intl.locale + "\/app\/"
  const newRegExp = new RegExp(regEx, "g")
  var regEx2 = "^\/" + intl.locale + "\/"
  const newRegExp2 = new RegExp(regEx2, "g")
  let prefix = ""
  if (mypath.match(newRegExp)) {
    prefix = '/' + intl.locale
  } else if (mypath.match(newRegExp2)) {
    prefix = '/' + intl.locale
  }
  
    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        if (!ref.current) {
          ref.current = true;
          setMyLang(localStorage.getItem('gatsby-intl-language') || '')
        }
        ref.current = value
      }, [value]);
      return ref.current;
    }

  const prevValue = usePrevious({myLang});
  useEffect(() => {
    if (prevUrl !== `${prefix}/app/settings/general` || prevUrl !== `${prefix}/app/settings/domains` || prevUrl !== `${prefix}/app/settings/email`) {
      if (mypath === `${prefix}/app/settings/general` || mypath === `${prefix}/app/settings/domains` || mypath === `${prefix}/app/settings/email`) {
        setOpen(true);
      }
    }   
    if (prevValue) {
      if (prevValue.myLang !== myLang) {
        if (myLang !== "en" || myLang === "") {
          changeLocale(myLang)
        }
      }
    }
  }, [prevUrl, myLang]);

  const handleSettings = (event, prevUrl) => {
    setPrevUrl(prevUrl)
    navigate(event.currentTarget.id)
  }

  const languages = Object.keys(languagesLocal)

  const langItems = () => {
    return (
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <ListItem key={language} style={{color: 'rgb(204, 204, 204)', paddingLeft: '0'}}>
              <Typography
                className={currentLocale === language ? classes.footerlangsel : classes.footerlang}
                component="p" variant="body2">
                <span onClick={() => changeLocale(language)} id={language}>{langs.getName(language, currentLocale).charAt(0).toUpperCase() + langs.getName(language, currentLocale).slice(1)}</span>
              </Typography>
            </ListItem>
          ))
        }
      </IntlContextConsumer>
    )
  }

  const footList = [
    intl.formatMessage({id: "foot.products"}),
    intl.formatMessage({id: "foot.support"}),
    intl.formatMessage({id: "foot.partners"}),
    intl.formatMessage({id: "foot.news"})
  ]
  const footListItem = [
    [
      [<a key="a1" style={{textDecoration: 'none', display: 'block'}} href="#"><Typography style={{color: '#ccc'}} variant="body1" component="span">{intl.formatMessage({id: "foot.emailmark"})}</Typography></a>],
      [<a key="a2" style={{textDecoration: 'none', display: 'block'}} href="#"><Typography style={{color: '#ccc'}} variant="body1" component="span">{intl.formatMessage({id: "foot.contman"})}</Typography></a>],
      [<a key="a3" style={{textDecoration: 'none', display: 'block'}} href="#"><Typography style={{color: '#ccc'}} variant="body1" component="span">Landing Page</Typography></a>]
    ],
    [<a key="b1" style={{textDecoration: 'none', display: 'block'}} href="mailto:support@acroleads.com" target="blank"><Typography style={{color: '#ccc'}} variant="body1" component="span">{intl.formatMessage({id: "foot.requesthelp"})}</Typography></a>],
    [<a key="c1" style={{textDecoration: 'none', display: 'block'}} href="https://www.appuro.com" target="blank"><Typography style={{color: '#ccc'}} variant="body1" component="span">Appuro</Typography></a>],
    [<a key="d1" style={{textDecoration: 'none', display: 'block'}} href="#"><Typography style={{color: '#ccc'}} variant="body1" component="span">Blog</Typography></a>]
   ]
  const fli = (index) => {
    return footListItem.map((v, i) => index === i ? <span key={i}>{v}</span> : '')
  }
  const footItems = footList.map((item, i) => (
    <ListItem
      key={i}
      style={{ color: "rgb(204, 204, 204)", paddingLeft: "0", alignSelf: 'flex-start' }}
    >
      <ListItemText primary={<Typography style={{color: 'white'}} component="p" component="p" type="body2">{item}</Typography>} secondary={fli(i)} />
    </ListItem>
  ))

  return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          color="default"
          position="fixed"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar>
            <NavBar/>
          </Toolbar>
        </AppBar>
        {isLoggedIn() && mypath !== "/" ? (
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.toolbar} />
              <List
                classes={{ root: classes.listroot }}
                component="div"
                disablePadding
              >
                <ListItem
                  button
                  onClick={handleClickSettings}
                  classes={{ root: classes.listitemroot }}
                  disableRipple
                  focusVisibleClassName={classes.focusVisible}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({id: "left.settings"})} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      selected={mypath === `${prefix}/app/settings/general`}
                      onClick={e => {
                        handleSettings(e, {
                          prevUrl: globalHistory.location.pathname,
                        })
                      }}
                      button
                      disableRipple
                      classes={{
                        root: classes.listitemroot,
                        selected: classes.selected,
                      }}
                      focusVisibleClassName={classes.focusVisible}
                      className={classes.nested}
                      id="/app/settings/general"
                    >
                      <ListItemText inset primary={intl.formatMessage({id: "left.gensettings"})} />
                    </ListItem>
                    <ListItem
                      selected={mypath === `${prefix}/app/settings/domains`}
                      onClick={e => {
                        handleSettings(e, {
                          prevUrl: globalHistory.location.pathname,
                        })
                      }}
                      button
                      disableRipple
                      classes={{
                        root: classes.listitemroot,
                        selected: classes.selected,
                      }}
                      focusVisibleClassName={classes.focusVisible}
                      className={classes.nested}
                      id="/app/settings/domains"
                    >
                      <ListItemText inset primary={intl.formatMessage({id: "left.domains"})} />
                    </ListItem>
                    <ListItem
                      selected={mypath === `${prefix}/app/settings/email`}
                      onClick={e => {
                        handleSettings(e, {
                          prevUrl: globalHistory.location.pathname,
                        })
                      }}
                      button
                      disableRipple
                      classes={{
                        root: classes.listitemroot,
                        selected: classes.selected,
                      }}
                      focusVisibleClassName={classes.focusVisible}
                      className={classes.nested}
                      id="/app/settings/email"
                    >
                      <ListItemText inset primary={intl.formatMessage({id: "left.email"})} />
                    </ListItem>
                  </List>
                </Collapse>
              </List>

              <List
                style={{ marginTop: 0 }}
                classes={{ root: classes.listroot }}
                component="div"
                disablePadding
              >
                <ListItem
                  button
                  onClick={handleClickLanding}
                  classes={{ root: classes.listitemroot }}
                  disableRipple
                  focusVisibleClassName={classes.focusVisible}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Landing Page" />
                </ListItem>
              </List>

              <List
                classes={{ root: classes.listroot }}
                component="div"
                disablePadding
              >
                <ListItem
                  onClick={() => navigate(`/app/contacts`)}
                  button
                  classes={{ root: classes.listitemroot }}
                  disableRipple
                  focusVisibleClassName={classes.focusVisible}
                >
                  <ListItemIcon>
                    <ContactsIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({id: "left.contacts"})} />
                </ListItem>
              </List>

              <List
                style={{ marginTop: 0 }}
                classes={{ root: classes.listroot }}
                component="div"
                disablePadding
              >
                <ListItem
                  onClick={() => navigate(`/app/email`)}
                  button
                  classes={{ root: classes.listitemroot }}
                  disableRipple
                  focusVisibleClassName={classes.focusVisible}
                >
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({id: "left.email"})} />
                </ListItem>
              </List>
            </Drawer>
        ) : <div></div>}
        <main  
          className={mypath === "/" ? classes.content : classes.contentglobal}
        >
          <div className={classes.toolbar} />
          <div style={{padding: 24}}>
          {children}
          </div>
          <footer className={classes.footer}>
              <div style={{maxWidth: 820, marginLeft: 'auto', marginRight: 'auto'}}>
              <div style={{textAlign: 'left'}}>
                <Typography style={{color: 'white'}} component="p" variant="body2" >
                  {intl.formatMessage({id: "foot.languages"}).toUpperCase()}
                </Typography>
              <div>
                <List className={classes.flexContainer}>
                  {langItems()}
                </List>
              </div>
              <div style={{marginTop: 28}}>
                <List className={classes.flexContainer2}>
                  {footItems}
                </List>
              </div>
             <div style={{textAlign: 'left', marginTop: 28}}>
                <Typography style={{color: 'white'}} component="p" variant="body2" >
                  {intl.formatMessage({id: "foot.followus"}).toUpperCase()}
                </Typography>
                <List className={classes.flexContainer}>
                  <ListItem style={{color: 'rgb(204, 204, 204)', paddingLeft: '0'}}>
                      <span><a href="https://www.facebook.com/Acroleads"><img src="https://d1vhh2lpbtr7th.cloudfront.net/images/footerface.png" alt="Smiley face" width="24" /></a></span>
                  </ListItem>
                  <ListItem style={{color: 'rgb(204, 204, 204)', paddingLeft: '0'}}>
                      <span><a href="https://www.instagram.com/acroleads/"><img src="https://d1vhh2lpbtr7th.cloudfront.net/images/footerinsta.png" alt="Smiley face" width="24" /></a></span>
                  </ListItem>
                  <ListItem style={{color: 'rgb(204, 204, 204)', paddingLeft: '0'}}>
                      <span><a href="https://twitter.com/acroleads"><img src="https://d1vhh2lpbtr7th.cloudfront.net/images/foottwitter.png" alt="Smiley face" width="24" /></a></span>
                  </ListItem>
                  <ListItem style={{color: 'rgb(204, 204, 204)', paddingLeft: '0'}}>
                      <span><a href="https://linkedin.com/linkedin"><img src="https://d1vhh2lpbtr7th.cloudfront.net/images/footlink.png" alt="Smiley face" height="24" /></a></span>
                  </ListItem>
                  <ListItem style={{color: 'rgb(204, 204, 204)', paddingLeft: '0'}}>
                      <span><a href="https://youtube.com/acroleads"><img src="https://d1vhh2lpbtr7th.cloudfront.net/images/footyoutube.png" alt="Smiley face" height="24" /></a></span>
                  </ListItem>
                </List>
              </div>
            </div>
            <div style={{color: 'white', textAlign: 'left', marginTop: 28}}>
              Acroleads© {new Date().getFullYear()}
              </div>
            </div>
          </footer>
          <Dialog
            open={opendialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {intl.formatMessage({id: "left.landing"})}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {intl.formatMessage({id: "left.soon"})}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </main>
        <Notification />
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
