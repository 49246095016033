import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Toolbar from '@material-ui/core/Toolbar';
import { getUser, isLoggedIn, logout } from '../services/auth';
import { useIntl, Link, navigate } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  links: {
    textDecoration: 'none'
  },
  avatar: {
    height: 30,
    width: 30
  },
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  nav: {
    width: 1200,
    margin: 'auto',
    height: 64,
    lineHeight: '64px',
    marginLeft: 'auto',
  }
}));

export default () => {
  const classes = useStyles();
  const content = { message: "", login: true }
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const intl = useIntl();

  const data = useStaticQuery(graphql `
    query {
      acroImage: file(relativePath: { eq: "acrologo.png" }) {
        childImageSharp {
          fixed(width: 316, height: 80) {
            src
            height
          }
        }
      }
    }
  `)


  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  function handleLogout(event) {
    logout(() => navigate(`/app/login`))
    handleClose(event)
  }

  if (isLoggedIn()) {
    content.message = `Hello, ${getUser().name}`
  } else {
    content.message = "You are not logged in"
  }

  const {height, src} = data.acroImage.childImageSharp.fixed

  return (
      <Toolbar className={classes.nav}>
        <Link className={classes.links} to="/">
            <img height={height / 2} src={src} style={{verticalAlign: 'middle'}} />
        </Link>
        <Link style={{marginLeft: 'auto'}} className={classes.links} to="/pricing/">
          <Button color="primary" className={classes.button}>
            {intl.formatMessage({id: "menu.pricing"})}
          </Button>
        </Link>
        {isLoggedIn() ? 
          <span>
            <a
              style={{display: 'inline-block', verticalAlign: 'middle'}}
              className={classes.links}
              href="/"
              onClick={event => {
                event.preventDefault()
              }}
            >
            <span>
              <Avatar
                ref={anchorRef}
                aria-controls="menu-list-grow"
                aria-haspopup="true"
                onClick={handleToggle}
                alt={getUser().name} src={getUser().avatar} className={classes.avatar} />
            </span>
            </a>
          </span> : <Link className={classes.links} to="/app/login">
            <Button color="primary" className={classes.button}>
              {intl.formatMessage({id: "menu.login"})}
            </Button>
          </Link>}
          <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper id="menu-list-grow">
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <MenuItem
                      onClick={event => {
                        event.preventDefault()
                        navigate(`/app/account`)
                        handleClose(event)
                      }}>{intl.formatMessage({id: "menu.account"})}</MenuItem>
                      <MenuItem onClick={handleLogout}>{intl.formatMessage({id: "menu.logout"})}</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
      </Toolbar>
  )
}