import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import "firebase/database";

const config = {
    apiKey: "AIzaSyDremTCu30Du6b1bmr301rrHdvq8jwZBmc",
    authDomain: "appurocom.firebaseapp.com",
    databaseURL: "https://appurocom.firebaseio.com",
    projectId: "appurocom",
    storageBucket: "appurocom.appspot.com",
    messagingSenderId: "773476380772",
};

let instance;

export default function getFirebase() {
    if (typeof window !== 'undefined') {
        if (instance) return instance;
        firebase.initializeApp(config);
        instance = firebase
        return instance;
    }

    return null;
}