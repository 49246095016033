// jshint ignore: start
import {
    SET_NOTIFICATION,
} from "../types";

export const setNotification = (message, open, variant) => ({
    type: SET_NOTIFICATION,
    message,
    open,
    variant
});