module.exports = {
    en: {
        default: true,
        path: `en`,
        locale: `en-US`,
        dateFormat: `DD/MM/YYYY`,
        siteLanguage: `en`,
        ogLanguage: `en_US`,
    },
    pt: {
        path: `pt`,
        locale: `pt-PT`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `pt`,
        ogLanguage: `pt_PT`,
    },
}